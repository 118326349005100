<template>
    <div
        data-component="WhatSystemCanDoItem"
        class="what-system-can-do__item"
        :class="{reverse}"
    >
        <div class="image" v-if="reverse">
            <img v-if="imgSrc" :src="imgSrc" :class="imgCustomClass" alt="Image">
        </div>
        <div class="information">
            <h1 v-if="title" v-text="title" />
            <ul>
                <li v-for="(inf, index) in informationList" :key="index">
                    {{ $ml.get(inf) }}
                </li>
            </ul>
        </div>
        <div class="image" v-if="!reverse">
            <img v-if="imgSrc" :src="imgSrc" :class="imgCustomClass" alt="Image">
        </div>
    </div>
</template>

<script>
export default {
    name: "WhatSystemCanDoItem",
    props: {
        reverse: {
            type: Boolean,
            default: false,
        },
        imgCustomClass: {
          type: String,
        },
        imgSrc: {
            type: String,
        },
        title: {
            type: String,
        },
        information: {
            type: String,
        },
        informationList: {
          type: Array,
        },
    },
}
</script>
