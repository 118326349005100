<template>
    <div data-component="FirstPage">
        <div class="first-section">
            <div class="wrapper vertical-center">
                <div class="first-section__inner-information">
                    <h1>{{ $ml.get("first_section_title") }}</h1>
                    <p>{{ $ml.get("first_section_description") }}</p>

                    <a
                      v-if="false"
                      href="https://map.kadastr-ua.com/?demo"
                      class="button"
                      target="_blank"
                    >
                      {{ $ml.get("DEMO") }}
                    </a>
                </div>
            </div>

            <div
                class="mouse-scroll"
                @click="mouseScroll"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "FirstPage",
    methods: {
        mouseScroll() {
            window.scrollTo(0, window.innerHeight);
        },
    }
}
</script>

<style scoped>

</style>
