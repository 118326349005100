<template>
    <div data-component="WhatSystemCanDo" id="WhatSystemCanDo">
        <div class="wrapper">
            <div class="what-system-can-do">
                <h1 class="title">{{ $ml.get("what_can_the_cadastre_ua_system") }}</h1>

                <what-system-can-do-item
                    v-for="(item, index) in items"
                    :reverse="item.reverse"
                    :img-src="item.img"
                    :img-custom-class="item.imgCustomClass"
                    :title="$ml.get(item.title)"
                    :information="item.information"
                    :informationList="item.informationList"
                    :key="`what-system-can-do-item__${index}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
//  images
import CardWithLogoImage from '@/assets/images/card-with-logo.webp';
import PopupsFromTheSiteImage from '@/assets/images/popups-from-the-site.webp';

import WhatSystemCanDoItem from "@/components/WhatSystemCanDoItem";

export default {
    name: 'WhatSystemCanDo',
    data() {
        return {
            items: [
                {
                    reverse: false,
                    img: CardWithLogoImage,
                    imgCustomClass: "mobile-from-center",
                    title: "it_is_convenient_to_search_for_information",
                    informationList: [
                      "what_system_can_do__it_is_convenient_to_search_for_information__information_1",
                      "what_system_can_do__it_is_convenient_to_search_for_information__information_2",
                      "what_system_can_do__it_is_convenient_to_search_for_information__information_3"
                    ]
                },
                {
                    reverse: true,
                    img: PopupsFromTheSiteImage,
                    imgCustomClass: "mobile-from-left",
                    title: "receive_store_and_analyze_data",
                    informationList: [
                      "what_system_can_do__receive_store_and_analyze_data__information_1",
                      "what_system_can_do__receive_store_and_analyze_data__information_2",
                      "what_system_can_do__receive_store_and_analyze_data__information_3",
                      "what_system_can_do__receive_store_and_analyze_data__information_4",
                      "what_system_can_do__receive_store_and_analyze_data__information_5"
                    ]
                },
            ],
        };
    },
    components: {
        WhatSystemCanDoItem,
    }
};
</script>
