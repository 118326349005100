<template>
  <div data-vue-component-name="FunctionalityAreTemporarilyLimited">
    <img src="@/assets/icons/warning.svg" alt="icon">
    <span class="text">УВАГА! У зв'язку з воєнним станом доступ до кадастрової інформації обмежений.</span>
  </div>
</template>

<script>
export default {
  name: 'FunctionalityAreTemporarilyLimited',
};
</script>

<style lang="scss" scoped>
[data-vue-component-name="FunctionalityAreTemporarilyLimited"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 9px 16px 9px 9px;
  background: red;
  text-align: center;

  img {
    margin-right: 4px;
  }

  .text {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.04px;
  }
}
</style>
